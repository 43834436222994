import clsx from "clsx";
import { AnimatePresence } from "framer-motion";
import parse from "html-react-parser";
import { sha512 } from "js-sha512";
import { useState } from "react";

import ContentSections from "@components/ContentSections";
import EmbeddedForm from "@components/EmbeddedForm";
import Message from "@components/Message";

const PasswordPageLayout = ({
  contentSections,
  encryptedPassword,
  onFormSuccess = () => null,
}) => {
  const [passwordValue, setPasswordValue] = useState("");

  const encryptedPasswordValue = sha512(
    `${passwordValue}${process.env.NEXT_PUBLIC_PW_SALT}`
  );

  const handleSubmit = (ev) => {
    ev.preventDefault();

    if (encryptedPasswordValue === encryptedPassword) {
      onFormSuccess();
    } else {
      alert("Incorrect password");
    }
  };

  return (
    <div className={clsx("PasswordPageLayout", "py-15")}>
      <div
        className={clsx(
          "PasswordPageLayout__columns",
          "flex flex-col lg:flex-row",
          "max-w-screen-xl mx-auto px-7.5",
          "space-y-15 lg:space-y-0"
        )}
      >
        <div
          className={clsx(
            "PasswordPageLayout__contentSections",
            "lg:w-1/2",
            "lg:px-7.5 lg:pr-30"
          )}
        >
          {!!contentSections && <ContentSections sections={contentSections} />}
        </div>

        <div
          className={clsx(
            "FormPageLayout__formContent",
            "lg:w-1/2",
            "lg:px-7.5"
          )}
        >
          <div className={clsx("py-15")}>
            <form onSubmit={handleSubmit}>
              <div className={clsx("mb-7.5")}>
                <label className={clsx("block", "bg-white-01")}>
                  <div className={clsx("text-xs", "opacity-50", "px-4 pt-2")}>
                    Password
                  </div>
                  <input
                    required
                    type="password"
                    className={clsx("form-input", "w-full", "pt-0")}
                    value={passwordValue}
                    onChange={(ev) => setPasswordValue(ev.target.value)}
                  />
                </label>
              </div>
              <div>
                <button
                  type="submit"
                  className={clsx(
                    "flex items-center justify-center ml-auto",
                    "h-10 px-5",
                    "bg-teal-01 text-white-01",
                    "rounded-sm"
                  )}
                >
                  {"Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordPageLayout;
